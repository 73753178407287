<template>
    <component :is="stage.component">
        <div class="font-black netlube-header text-red mb-2">{{ stage.heading }}</div>
    </component>
</template>

<script>
import Start from './Start'
import Refine from './Refine'
import Results from './Results'
import Recommendations from './Recommendations'
import State, { goTo } from './State'
import { computed, watch, ref } from 'vue'

export default {
    setup() {
        const stages = [
            { component: Start, heading: 'Search vehicle type', param: '' },
            { component: Refine, heading: 'Refine search for', param: '?refine' },
            { component: Results, heading: 'Search results for', param: '?results' },
            { component: Recommendations, heading: 'Recommendations for', param: '?recommend' }
        ]

        history.replaceState(null, null, location.pathname)

        const statePopped = ref(false)

        function goBasedOnParams() {
            statePopped.value = true
            const name = {
                '': 'Start',
                '?refine': 'Refine',
                '?results': 'Results',
                '?recommend': 'Recommendations'
            }[location.search]
            goTo(name)
        }

        const stage = computed(() => stages[State.selectedStage])

        watch(stage, newStage => {
            if (statePopped.value) {
                statePopped.value = false
                return
            }
            history.pushState(null, null, location.pathname + newStage.param)
        })

        window.addEventListener('popstate', goBasedOnParams)

        return { stage }
    }
}
</script>

<style scoped>
.netlube-header {
    font-size: 24px;
}

@screen lg {
    .netlube-header {
        font-size: 28px;
    }
}
</style>
