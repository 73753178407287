<template>
    <div class="bg-blue-tint px-6 lg:px-10 pt-6 pb-12">
        <slot></slot>
        <div class="flex h-12 md:h-14 w-full md:w-4/5 xl:w-3/5 mb-8">
            <div class="relative h-full w-full w-full border border-black" search-container>
                <input :value="State.searchText" @input="setSearchText($event)"
                    @keyup.enter="performSearch" type="text"
                    class="pl-14 pr-5 h-full w-full md:text-lg"
                    placeholder="Search model or description">
            </div>
            <button @click="performSearch" class="hidden md:block btn px-6 text-base">Search</button>
        </div>
        <h4 class="h4 mb-2 font-black">Search by category</h4>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div v-for="section in sections" :key="section.id"
                @click="changeSection(section.id)"
                class="h-24 relative flex items-center bg-white group shadow-lg px-5 py-3 cursor-pointer" style="margin-top: 0; line-height: 19.2px;">
                <img :src="section.icon">
                <h5 class="h5 text-base ml-3 text-red group-hover:underline uppercase">{{ section.type }}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import State, { changeSection, setSearchText, performSearch } from './State'
import { computed } from 'vue'

export default {
    setup() {
        function filterByUniqueType(value, index, self) {
            return self.findIndex(v => v.type === value.type) === index
        }

        const uniqueSections = computed(() => {
            return State.sections.filter(filterByUniqueType)
        })

        return {
            sections: uniqueSections,
            changeSection,
            performSearch,
            setSearchText,
            State
        }
    }
}
</script>

<style scoped>

[search-container]::before {
    position: absolute;
    top: 12px;
    left: 20px;
    content: '';
    background-image: url('/img/search.svg');
    background-size: 20px 20px;
    height: 20px;
    width: 20px;
}

@screen md {
    [search-container]::before {
        top: 16px;
    }
}
</style>