<template>
    <div>
        <div class="bg-blue-tint w-full md:w-5/6 mx-auto px-6 lg:px-10 pt-6 pb-12">
            <slot></slot>
            <div class="flex flex-col md:flex-row justify-between items-start md:items-center">
                <h4 class="h4 font-black mb-4 md:mb-0">"{{ State.searchText }}"</h4>
                <button @click="goTo('Start')" start-over class="btn h-12 pl-12 pr-5 relative text-sm">Start Over</button>
            </div>
        </div>
        <div class="w-full md:w-2/3 px-6 md:px-0 mx-auto">
            <div class="w-full text-center font-bold my-8 md:my-12">
                We found {{ State.searchResults.length }} possible matches
            </div>
            <div v-for="result in State.searchResults" :key="result.id"
                class="border-b border-black py-4 flex justify-between">
                <div class="pr-2">
                    <h4 @click="getModelFromApi(result.id)"
                        class="h4 font-black mb-0 cursor-pointer">
                        {{ makeAndModel(result) }}
                    </h4>
                    <div class="font-bold" :class="{ 'mb-8': !yearRange(result) }">
                        {{ yearRange(result) }}
                    </div>
                    {{ result.modelMinorName || result.modelMajorName }}
                </div>
                <div class="hidden md:flex items-center">
                    <button @click="getModelFromApi(result.id)" class="btn text-sm">Select</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import State, { goTo, getModelFromApi } from './State'

export function makeAndModel(model) {
    if (!model.modelMinorName) return model.manufacturerName
    return `${model.manufacturerName} ${model.modelMajorName}`
}

export function yearRange(model) {
    if (!model.yearStart && !model.yearEnd) return ''
    return `${model.yearStart}-${model.yearEnd}`
}

export default {
    setup() {
        return { State, goTo, makeAndModel, yearRange, getModelFromApi }
    }
}
</script>
