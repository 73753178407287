import { createApp } from 'vue'
import NetlubeMain from '../vue/Netlube Tool/Main'
import gsap from 'gsap';
import MicroModal from 'micromodal';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import Swiper, { Navigation, Pagination } from 'swiper';
var Sticky = require("sticky-js");

if (document.getElementById('netlube-tool')) {
    createApp(NetlubeMain).mount('#netlube-tool')
}

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    var sticky = new Sticky(".season-nav"); // used on .season-nav

    // MicroModal.init();
    MicroModal.init({
        // onShow: modal => console.info(`${modal.id} is shown`), // [1]
        // onClose: modal => console.info(`${modal.id} is hidden`), // [2]
        onClose: function(modal, element, event) {
            event.preventDefault();
            event.stopPropagation();
            closeModalOverlay(modal.id);
            // console.info(`${modal.id} is hidden`);
        },
        openTrigger: 'data-custom-open', // [3]
        closeTrigger: 'data-custom-close', // [4]
        openClass: 'modal--active', // [5]
        disableScroll: true, // [6]
        disableFocus: false, // [7]
        awaitOpenAnimation: false, // [8]
        awaitCloseAnimation: false, // [9]
        debugMode: false // [10]
      });
      

    function closeOverlay(){
        console.log("hello");
    }

    // Reset youtube embed video on popup close
    // const videoModalCloseBtn = document.querySelector('.video-modal .modal__close'),
    // videoModalIframe = document.querySelector('.video-modal .responsive-embed iframe'),
    // videoMobileBtn = document.querySelector('.banner__play');
    // if(videoModalIframe) {

    //     videoModalIframe.dataset.src = videoModalIframe.src;
    //     videoModalIframe.src = "";

    //     const videoPlayBtn = document.querySelector('[data-modal]');

    //     // Play iframe inline for mobile view
    //     if(videoMobileBtn) {
    //         videoMobileBtn.addEventListener('click', () => {
    //             if(videoModalIframe.dataset.src) {
    //                 let ifrm = document.createElement('iframe');
    //                 let iframeSrc = videoModalIframe.dataset.src + "&autoplay=1";
    //                 ifrm.setAttribute('src', iframeSrc);
    //                 videoMobileBtn.parentElement.appendChild(ifrm);
    //             }
    //         });
    //     }

    //     videoPlayBtn.addEventListener('click', () => {
    //         if(videoModalIframe.dataset.src) {
    //             videoModalIframe.src = videoModalIframe.dataset.src;
    //         }
    //     });
    //     videoModalCloseBtn.addEventListener('click', () => {
    //         videoModalIframe.dataset.src = videoModalIframe.src;
    //         videoModalIframe.src = "";
    //     });
    // }

    // Animations
    gsap.utils.toArray('.animate').forEach(item => {
        ScrollTrigger.create({
            trigger: item,
            start: 'top 90%',
            toggleClass: 'animate--active',
            once: true,
        });
    });


    // Animations - drop borders
    gsap.utils.toArray('.drop-border:not(.drop-border--hover)').forEach(section => {
        ScrollTrigger.create({
            trigger: section,
            start: 'top 40%',
            end: 'bottom 40%',
            toggleClass: 'drop-border--active',
        });
    });


    // Swiper
    Swiper.use([Navigation, Pagination]);

    var titles = [],
	    els = document.querySelectorAll('.swiper-slide'),
	    i = 0;
		for (i = 0; i < els.length; i++) {
		    if (els[i]) {
		        titles.push(els[i].dataset.slideTitle);
		    }
		}

    const swiper = new Swiper('.swiper-container', {
        // direction: 'vertical',
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + titles[index] + '</span>';
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Mobile navigation
    const navTriggers = document.querySelectorAll('.js-nav-btn');
    const panelTriggers = document.querySelectorAll('.js-panel-btn');
    const mobNav = document.querySelector('.mobile');
    const mobNavOverlay = document.querySelector('.mobile-overlay');

    if(navTriggers){
        navTriggers.forEach(function(navTrigger) {
            bindEvent(navTrigger, "click", function() {
                event.preventDefault();
                document.body.classList.toggle('modal-open');
                navTrigger.classList.toggle('nav-icon--active');
                mobNav.classList.add('mobile--transition');
                mobNav.classList.toggle('mobile--active');
                navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }, false);
        });
    }

    if (panelTriggers) {
        for (let i = 0, l = panelTriggers.length; i < l; i += 1) {
            panelTriggers[i].addEventListener('click', (event) => {
                event.preventDefault();
                const panelId = event.currentTarget.dataset.panelId;
                const panel = document.getElementById(`panel-${panelId}`);
                panel.classList.toggle("mobile__panel--active");
            });
        }
    }


    // Modals
    // const modal = document.querySelector('.modal');
    // const modalTriggers = document.querySelectorAll('.js-modal-btn');
    // const mobalOverlay = document.querySelector('.modal-overlay');

    // if(modalTriggers){
    //     modalTriggers.forEach(function(modalTrigger) {
    //         bindEvent(modalTrigger, "click", function() {
    //             event.preventDefault();
    //             modal.classList.toggle('modal--active');
    //             mobalOverlay.classList.toggle('modal-overlay--active');
    //             const modalVideo = modal.querySelector('.modal__video');
    //             modalVideo ? modalVideo.src = modalVideo.getAttribute('data-src') : '';
    //         }, false);
    //     });
    // }

    // Video modals


    // Video modals
    
    // Form fields and labels
    var formLabels = document.querySelectorAll('.form-input--text');

    if (formLabels) {
        formLabels.forEach(function(element) {
            element.addEventListener('blur', function(event) {
                if (!event.target.value){
                    event.target.previousElementSibling.classList.remove('form-label--filled');
                }
            });
            element.addEventListener('focus', function(event) {
                event.target.previousElementSibling.classList.add('form-label--filled');
            });

            // When showing the form error, input labels overlapping the content inside the field
            if(element.innerHTML != '' || element.value != '') {
                element.previousElementSibling.classList.add('form-label--filled');
            }
        })
    }

    // Video modals
    const modalTriggers = document.querySelectorAll('.video-playbtn');
    // const modalOverlay = document.querySelector('.modal-overlay');
    if(modalTriggers){
        modalTriggers.forEach(function(modalTrigger) {
            bindEvent(modalTrigger, "click", function() {
                var modalOverlay = document.getElementById('modal-overlay-'+modalTrigger.getAttribute('id').replace("modal-play-", ""));
                event.preventDefault();
                modalOverlay.classList.toggle('modal-overlay--active');
            }, false);
        });
    }

    // const modalCloseTriggers = document.querySelectorAll('.modal-ovelay-close');
    // if(modalCloseTriggers){
    //     modalCloseTriggers.forEach(function(modalCloseTrigger) {
    //         bindEvent(modalCloseTrigger, "click", function() {
    //             var modalOverlay = document.getElementById('modal-overlay-'+modalCloseTrigger.getAttribute('id').replace("modal-", ""));
    //             event.preventDefault();
    //             modalOverlay.classList.remove('modal-overlay--active');
    //         }, false);
    //     });
    // }

    function closeModalOverlay(modalId){
        var modalOverlay = document.getElementById('modal-overlay-'+modalId.replace("modal-", ""));
        modalOverlay.classList.remove('modal-overlay--active');
    }

    // Video modals

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})
