<template>
    <div class="bg-blue-tint w-full md:w-5/6 mx-auto px-6 lg:px-10 pt-6 pb-12">
        <slot></slot>
        <h4 class="h4 font-black mb-4 md:mb-6">{{ State.section?.type }}</h4>
        <div class="w-full md:w-2/3 mx-auto">
            <template v-for="(option, key) in State.options" :key="option.id">
                <div>{{ key }}</div>
                <select :disabled="dependIsMissing(option)" :value="option.selected"
                    @change="setSelected(key, $event.target.value)"
                    class="w-full px-5 h-12 border border-black mb-5"
                    :class="{ 'text-grey-600': dependIsMissing(option) }" :id="key">
                    <option value="" disabled>
                        {{ placeholderText(option) }}
                    </option>
                    <option v-for="value in option.values" :value="value.id"
                        :key="value.id">
                        {{ optionText(key, value) }}
                    </option>
                </select>
            </template>
            <div class="w-full flex justify-between h-12 my-6">
                <button @click="goTo('Recommendations')" class="btn text-sm px-4"
                    :class="{ 'invisible': State.options['Model'].selected != State.model?.id }">
                    View Results
                </button>
                <button @click="goTo('Start')" class="btn pl-12 pr-5 text-sm relative" start-over>Start Over</button>
            </div>
        </div>
    </div>
</template>

<script>
import State, { setSelected, goTo } from './State'
import { onMounted } from 'vue'
export default {
    setup() {
        onMounted(() => {
            const placeholders = document.querySelectorAll('option[disabled]')
            placeholders.forEach(option => {
                if (!option.parentElement.value)
                option.selected = true
            })
        })

        Object.keys(State.options).forEach(setDefaultSelected)

        function setDefaultSelected(key) {
            const option = State.options[key]
            if (option.values?.length === 1 && option.selected === '')
            setSelected(key, option.values[0].id)
        }
        
        function dependIsMissing(option) {
            if (!option.dependsOn) return false
            return !State.options[option.dependsOn].selected
        }

        function optionText(optionKey, value) {
            return {
                'Sub-category': value.subtype || value.type,
                'Year': value.year
            }[optionKey] ?? value.name
        }

        function placeholderText(option) {
            if (!dependIsMissing(option)) return '--Please select--'
            return {
                2: '--Select a sub-category first--',
                3: '--Select a make first--',
                4: '--Select a year first--',
            }[option.id]
        }

        return { State, dependIsMissing, placeholderText, setSelected, optionText, goTo }
    }
}
</script>