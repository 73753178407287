import { reactive, readonly, watch, computed } from 'vue'

//API setup
const axios = require('axios')
const api = axios.create({ baseURL: '/api' })

//State
const state = reactive({
    selectedStage: 0, //0 = Start, 1 = Refine, 2 = Results, 3 = Recommendations
    searchText: '',
    searchResults: null,
    sections: [], //All top-level sections
    section: null, //Selected top-level section
    model: null,
    options: { //Options for refining
        'Sub-category': { id: 1, selected: '', values: computed(() => {
            return state.sections.filter(x => x.type === state.section?.type)
        }) },
        'Make': { id: 2, dependsOn: 'Sub-category', selected: '', values: [] },
        'Year': { id: 3, dependsOn: 'Make', selected: '', values: [] },
        'Model': { id: 4, dependsOn: 'Year', selected: '', values: [], allValues: [] }
    }
})

//Initiate sections array
api.get('/sections').then(({ data }) => {
    state.sections = data.map(formatSection)
})

//Format sections to accomodate unique top-level categories
function formatSection(section) {
    const type = section.name.split(' - ')[0]
    const replacement = section.name.replace(type + ' - ', '')
    const subtype = replacement === type ? '' : replacement
    const icon = `/assets/netlube-icons/${section.id}.svg`
    return { id: section.id, type, subtype, icon }
}

//Reset selected options to blank when top-level section changes
watch(() => state.section?.id, () => {
    Object.keys(state.options).forEach(key => setSelected(key, ''))
    state.options.Year.values = []
    state.options.Model.values = []
    state.options.Model.allValues = []
})

//Retrieve new makes/manufacturers when selected sub-category changes
watch(() => state.options['Sub-category'].selected, value => {
    setSelected('Make', '')
    if (!value) {
        state.options['Make'].values = []
        return
    }
    api.get(`/manufacturers?value=${value}`).then(({data}) => {
        state.options['Make'].values = data
    })
})

//Retrieve new years & models when selected make/manufacturer changes
watch(() => state.options['Make'].selected, value => {
    setSelected('Year', '')
    if (!value) return

    api.get(`/models?value=${value}`).then(({data}) => {
        state.options['Year'].values = formatYears(data)
        state.options['Model'].values = data
        state.options['Model'].allValues = data
    })
})

watch(() => state.options['Year'].selected, value => {
    setSelected('Model', '')
    if (!value) return
    const model = state.options['Model']
    model.values = model.allValues.filter(model => {
        const chosenYear = +value
        return chosenYear >= model.yearStart && chosenYear <= model.yearEnd
    })
})

watch(() => state.options['Model'].selected, value => {
    if (!value) return
    getModelFromApi(value)
})

export function getModelFromApi(value) {
    api.get(`/recommendations?value=${value}`).then(({data}) => {
        state.model = data
        goTo('Recommendations')
    })
}

function formatYears(models) {
    const allYears = models.map(getYearsBetween)
    const flatYears = [].concat(...allYears)
    const uniqueYears = flatYears.filter((value, index, self) => {
        return self.indexOf(value) === index
    }).sort().reverse()

    const formattedYears = uniqueYears.map(value => {
        return { id: value, year: value }
    })

    return formattedYears
}

function getYearsBetween(model) {
    const start = model.yearStart
    const end = model.yearEnd
    const arr = []
    let date = start - 1
    while (date < end) {
        date++
        arr.push(date)
    }
    return arr
}

//Function for retrieving data for certain options
async function getOptionValues(endpoint, selected) {
    const { data } = await api.get(endpoint.replace('xx', selected))
    return data
}

//Change selected section
export function changeSection(id) {
    state.section = state.sections.find(x => x.id === id)
    goTo('Refine')
}

export function setSearchText(event) {
    state.searchText = event.target.value
}

export function performSearch() {
    if (!state.searchText) return
    api.get('/search?search=' + state.searchText).then(({data}) => {
        state.searchResults = data.results
        goTo('Results')
    })
    
}

export function goTo(stage) {
    state.selectedStage = {
        start: 0,
        refine: 1,
        results: 2,
        recommendations: 3
    }[stage.toLowerCase()] ?? 0
}

export function setSelected(key, value) {
    state.options[key].selected = value
}

export default readonly(state)