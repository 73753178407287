<template>
    <div>
        <div class="bg-blue-tint w-full md:w-5/6 mx-auto px-6 lg:px-10 pt-6 pb-12">
            <slot></slot>
            <div>
                <h4 class="h4 font-black mb-0">{{ makeAndModel(State.model) }}</h4>
                <div class="font-bold" :class="{ 'mb-8': !yearRange(State.model) }">
                    {{ yearRange(State.model) }}
                </div>
                {{ State.model.modelMinorName || State.model.modelMajorName }}
            </div>
            <div class="flex flex-col lg:flex-row justify-between mt-6">
                <select class="w-full md:w-3/4 lg:w-1/2 px-5 h-12 border border-black mb-4 lg:mb-0"
                    @change="goToCompartment">
                    <option value="" selected disabled>Refine by use</option>
                    <option v-for="compartment in State.model.compartments" :key="compartment.name"
                        :value="'#' + compartment.name">
                        {{ compartment.name }}
                    </option>
                </select>
                <div class="flex h-12 gap-3 md:gap-5 justify-between">
                    <button @click="updateModel()" class="btn px-2 md:px-5 text-sm hover:text-white hover:border-blue bg-transparent text-red border-red border">Update Model</button>
                    <button @click="goTo('Start')" start-over class="btn relative pl-12 pr-5 text-sm">Start Over</button>
                </div>
            </div>
        </div>
        <div v-for="(compartment, index) in State.model.compartments" :key="compartment.name"
            class="mt-12 mb-32 px-6 md:px-0">
            <h3 :id="compartment.name" class="h3 font-black mb-0">
                {{ compartment.name }}
            </h3>
            <div class="text-base" v-if="compartment.volumeDescription">
                {{ compartment.volumeDescription }}
                <span class="ml-2" v-if="compartment.volumeNote">
                    ({{ compartment.volumeNote }})
                </span>
            </div>
            <div class="border-t border-black mt-2">
                <div v-if="compartment.notes.length"
                    style="background-color: #FFE7E3;"
                    class="inline-block px-3 py-1 text-base mt-3">
                    {{ compartment.notes.join(', ') }}
                </div>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-10">
                    <div v-for="lube in compartment.lubricants" :key="lube.name">
                        <img v-if="lube.image" class="pt-8 w-1/3 mx-auto" :src="getImage(lube)">
                        <div class="w-full pt-8">
                            <h4 class="h4 mb-2 text-base md:text-lg font-black">{{ lube.name }}</h4>
                            <div :id="index + lube.name" class="text-sm md:text-base five-lines"
                                v-html="lube.description"></div>
                            <div v-if="lineClampActivated(lube, index)"
                                @click="toggleLineClamp(lube, index, $event)"
                                class="text-customthree hover:underline cursor-pointer inline">
                                See more
                            </div>
                            <a :href="lube.weblink" stockist v-if="lube.weblink"
                                class="relative font-bold text-red text-base pl-6">
                                FIND A STOCKIST
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import State, { goTo, changeSection } from './State'
import { makeAndModel, yearRange } from './Results'
import { onMounted, ref } from '@vue/runtime-core'

export default {
    setup() {
        function goToCompartment(e) {
            window.location = e.target.value
            e.target.value = ''
        }

        function getImage(lube) {
            return 'https://www.datateck.com.au/Lube/Mobil_NZ/ImagesLube/' + lube.image
        }

        const isMounted = ref(false)
        onMounted(() => isMounted.value = true)

        function updateModel() {
            if (State.section?.id === State.model.sectionID) return goTo('Refine')
            changeSection(State.model.sectionID)
        }

        function lineClampActivated(lube, index) {
            if (!isMounted.value) return
            const description = document.getElementById(index + lube.name)
            return description.scrollHeight > description.clientHeight
        }

        function toggleLineClamp(lube, index, e) {
            const description = document.getElementById(index + lube.name)
            description.classList.toggle('five-lines')
            e.target.innerText = e.target.innerText === 'See more' ? 'See less' : 'See more'
        }

        return {
            State,
            makeAndModel,
            yearRange,
            goTo,
            goToCompartment,
            getImage,
            lineClampActivated,
            toggleLineClamp,
            updateModel
        }
    }
}
</script>

<style scoped>
.five-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5; 
    -webkit-box-orient: vertical;
}

[stockist]::before {
    content: '';
    position: absolute;
    top: 4px;
    left: -3px;
    transform: rotate(315deg);
    border-bottom: 3px solid #D81E05;
    border-right: 3px solid #D81E05;
    height: 11px;
    width: 11px;
}
</style>